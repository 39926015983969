import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import '../styles/FinancialReports.css';
import { Container, Row, Col } from 'react-bootstrap'
import Q12020 from '../assets/Q12020.PDF'
import Q22020 from '../assets/Q22020.PDF'
import Q32020 from '../assets/Q32020.PDF'
import Q42020 from '../assets/Q42020.PDF'
import Q12021 from '../assets/Q12021.PDF'
import Q22021 from '../assets/Q22021.PDF'
import Q32021 from '../assets/Q32021.PDF'
import Q42021 from '../assets/Q42021.PDF'
import Q12022 from '../assets/Q12022.PDF'
import Q22022 from '../assets/Q22022.PDF'
import Q32022 from '../assets/Q32022.pdf'
import Q42022 from '../assets/Q42022.pdf'
import Q12023 from '../assets/Q12023.pdf'
import Q22023 from '../assets/Q22023.pdf'
import Q32023 from '../assets/Q32023.pdf'
import Q42023 from '../assets/Q42023.pdf'
import Q12024 from '../assets/Q12024.pdf'
import Q22024 from '../assets/Q22024.pdf'


const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'financialreports', content: 'FINANCIAL REPORTS', active: true },
]

function handleClick(event) {
    event.preventDefault();
}

function FinancialReports() {

    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: "#fff" }} />
            </div>


            <div className="fr-header">Financial Reports</div>
            <div className="fr-subheader">Quarterly and Annual Reports</div>
            <div className="rec-pre" style={{ width: 'auto', height: 'auto' }}>
                <div className="rectangle" >    
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px auto' }}>
                        <Row style={{ width: '950px', fontWeight: 'bold', fontSize: '1.5625rem !important' }}>
                            <Col><div className="report-name"><a href={Q12024} target="_blank" rel="noopener noreferrer">2024 Q1</a></div></Col>
                            <Col><div className="report-name"><a href={Q22024} target="_blank" rel="noopener noreferrer">2024 Q2</a></div></Col>
                            {/* <Col><div className="report-name"><a href={Q22023} target="_blank" rel="noopener noreferrer">2023 Q2</a></div></Col>
                            <Col><div className="report-name"><a href={Q32023} target="_blank" rel="noopener noreferrer">2023 Q3</a></div></Col>
                            <Col><div className="report-name"><a href={Q42023} target="_blank" rel="noopener noreferrer">2023 Q4</a></div></Col> */}
                        </Row>
                    </Container>                
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px auto' }}>
                        <Row style={{ width: '950px', fontWeight: 'bold', fontSize: '1.5625rem !important' }}>
                            <Col><div className="report-name"><a href={Q12023} target="_blank" rel="noopener noreferrer">2023 Q1</a></div></Col>
                            <Col><div className="report-name"><a href={Q22023} target="_blank" rel="noopener noreferrer">2023 Q2</a></div></Col>
                            <Col><div className="report-name"><a href={Q32023} target="_blank" rel="noopener noreferrer">2023 Q3</a></div></Col>
                            <Col><div className="report-name"><a href={Q42023} target="_blank" rel="noopener noreferrer">2023 Q4</a></div></Col>                            
                        </Row>
                    </Container>
                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px auto' }}>
                        <Row style={{ width: '950px', fontWeight: 'bold', fontSize: '1.5625rem !important' }}>
                            <Col><div className="report-name"><a href={Q12022} target="_blank" rel="noopener noreferrer">2022 Q1</a></div></Col>
                            <Col><div className="report-name"><a href={Q22022} target="_blank" rel="noopener noreferrer">2022 Q2</a></div></Col>
                            <Col><div className="report-name"><a href={Q32022} target="_blank" rel="noopener noreferrer">2022 Q3</a></div></Col>
                            <Col><div className="report-name"><a href={Q42022} target="_blank" rel="noopener noreferrer">2022 Q4</a></div></Col>
                        </Row>
                    </Container>

                    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px auto' }}>
                        <Row style={{ width: '950px', fontWeight: 'bold', fontSize: '1.5625rem !important' }}>
                            <Col><div className="report-name"><a href={Q12021} target="_blank" rel="noopener noreferrer">2021 Q1</a></div></Col>
                            <Col><div className="report-name"><a href={Q22021} target="_blank" rel="noopener noreferrer">2021 Q2</a></div></Col>
                            <Col><div className="report-name"><a href={Q32021} target="_blank" rel="noopener noreferrer">2021 Q3</a></div></Col>
                            <Col><div className="report-name"><a href={Q42021} target="_blank" rel="noopener noreferrer">2021 Q4</a></div></Col>
                        </Row>
                    </Container>                   
                </div>
            </div>
            <div className="disclaimer">** Only displaying last 3 years</div>
            < br />
            < br />
            < br />
            < br />

        </>
    )
}

export default FinancialReports
