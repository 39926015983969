import React, { useState } from 'react';
import '../styles/Footer.css';
import { Input } from "semantic-ui-react";
import { Link } from 'react-router-dom';
/*import claimForm from '../assets/claim_Form.pdf';*/
import TALIClaimForm from '../assets/TALI_Claim_Form.pdf';
import TLIClaimForm from '../assets/TLI_Claim_Form.pdf';
import TTSIClaimForm from '../assets/TTSI_Claims_Form.pdf';
import claimProcedure from '../assets/Claims_Proceedures.pdf';
import creditApplication from '../assets/credit_Application.pdf';
import creditAuthorization from '../assets/credit_Card_Auth.pdf';
/*import Presentation from '../assets/ttr_Presentation.pdf';*/
// import Presentation from '../assets/Corporate_Presentation_May2023_v2.pdf'; ttr_Presentation_11_28_2023
import Presentation from '../assets/ttr_Presentation.pdf';
import { Container, Row, Col } from "react-bootstrap";
import Modal from 'react-modal';
// new footer images 
import Globe from '../assets/globe-and-mail.png';
import Trucking from '../assets/todays-trucking.png';
import Smartway from '../assets/smartway-partner.png';

import English from '../assets/Accessibility.pdf';
import French from '../assets/AccessibilityFrench.pdf';

// Environment Variables: https://create-react-app.dev/docs/adding-custom-environment-variables/#adding-development-environment-variables-in-env
require('dotenv').config();

//// Load the AWS SDK for Node.js
//var AWS = require("aws-sdk");

//const SES_CONFIG = {
//    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
//    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//    region: 'us-east-1',
//};

//const AWS_SES = new AWS.SES(SES_CONFIG);

function Footer() {

    const [subscriptionFormMessage, setSubscriptionFormMessage] = useState("");

    function sendEmail(e) {
        //https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/ses-examples-sending-email.html
        //https://betterprogramming.pub/how-to-send-emails-with-node-js-using-amazon-ses-8ae38f6312e4

        e.preventDefault();
        setSubscriptionFormMessage("");

        var emailBody = `${e.target.firstName.value} ${e.target.lastName.value} ${e.target.email.value} ${e.target.company.value}`;

        let body = {
            "subject": "Investors Subscription Request",
            "body": emailBody,
            "recipient": "investors@ttgi.com"
        }

        // create the headers for the fetch 
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');

        // add the orders via api call
        fetch('https://api.ttgi.com/wwwEmail/send', {
            headers: myHeaders,
            method: "POST",
            body: JSON.stringify(body)
        }).then((response) => {
            //set the error message
            if (response.status != 200) {
                console.log(response);
                setSubscriptionFormMessage('You are successfully subscribed to Titanium News!');
            }
            //return response.json();
        })
            //.then(data => {

            //})
            .catch(function (error) {
                setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
            });

        //// Create sendEmail params 
        //var params = {
        //    Destination: { /* required */
        //        CcAddresses: [
        //            // 'dev@ttgi.com',
        //            /* more items */
        //        ],
        //        ToAddresses: [
        //            'investors@ttgi.com'
        //            /* more items */
        //        ]
        //    },
        //    Message: { /* required */
        //        Body: { /* required */
        //            Html: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            },
        //            Text: {
        //                Charset: "UTF-8",
        //                Data: emailBody
        //            }
        //        },
        //        Subject: {
        //            Charset: 'UTF-8',
        //            Data: 'Investors Subscription Request'
        //        }
        //    },
        //    Source: 'donotreply@ttgi.com', /* required */
        //    ReplyToAddresses: [
        //        'donotreply@ttgi.com',
        //        /* more items */
        //    ],
        //};

        //// Create the promise and SES service object
        //var sendPromise = AWS_SES.sendEmail(params).promise();

        //// Handle promise's fulfilled/rejected states
        //sendPromise.then(
        //    function (data) {
        //        console.log(data.MessageId);
        //        setSubscriptionFormMessage('You are successfully subscribed to Titanium News!');
        //    }).catch(
        //        function (err) {
        //            setSubscriptionFormMessage('There was an error processing your request. Please try again later or contact investors@ttgi.com.');
        //            console.error(err, err.stack);
        //        });

        e.target.reset();
    }

    /*************** REACT MODAL VARS AND FUNCTIONS ***************/

    // var to help show and hide modal
    const [showModal, setShowModal] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState(false);

    // func for modal show and hide
    function handleOpenModal() {
        setShowModal(true);
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    // func for modal show and hide
    function handleOpenAccessModal() {
        setShowAccessModal(true);
    }

    function handleCloseAccessModal() {
        setShowAccessModal(false);
    }

    // styling modal 
    const customStyles = {
        content: {
            position: "fixed",
            //display: "flex",
            justifyContent: "center",
            top: "35%",
            left: "38%",
            width: '20%',
            height: '200px',
            zIndex: "1001",
            overflowY: "auto"
        },
    };

    // modal display func    
    function setModalDetails() {
        return (
            <Modal
                isOpen={showModal}
                contentLabel="close modal"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                style={customStyles}
            >
                <div class="container h-100">
                    <div class="row h-100 justify-content-center">
                        <h1>Select your claim</h1>
                        <a href={TALIClaimForm} target="_blank">Titanium American Logistics Inc.</a>
                        <a href={TLIClaimForm} target="_blank">Titanium Logistics Inc.</a>
                        <a href={TTSIClaimForm} target="_blank">Titanium Trucking Services Inc.</a>
                    </div>
                </div>
            </Modal>);
    }

    // modal display func    
    // TODO: check this modal for AA standards 
    function setModalAccessDetails() {
        return (
            <Modal
                isOpen={showAccessModal}
                contentLabel="close modal"
                onRequestClose={handleCloseAccessModal}
                shouldCloseOnOverlayClick={true}
                style={customStyles}
            >
                <div class="container h-100">
                    <div class="row h-100 justify-content-center">
                        <a href={English} target="_blank">    Accessibility Standards (English)</a> <br />
                        <a href={French} target="_blank">     Accessibility Standards (French)</a> <br />
                    </div>
                </div>
            </Modal>);
    }

    /*************** REACT MODAL VARS AND FUNCTIONS ***************/

    return (
        <>
            {setModalDetails()}
            {setModalAccessDetails()}
            <div className="footer-container">
                <div className="footer">
                    <Container fluid>
                        <Row className="justify-content-between">
                            <Col xs={12} lg={5} xl={4}>
                                <Container fluid>
                                    <Row className="align-items-start justify-content-center">
                                        <Col className="footer-heading">
                                            <h2>COMPANY</h2>
                                            <Link to='/aboutus'>About Us</Link>
                                            <Link to={{ pathname: "https://recruiting.ultipro.ca/TIT5000TTG/JobBoard/78b7a01b-cbed-4d4b-93ab-0a635eaebefb" }} target="_blank">Careers</Link>
                                            <Link to='/contactus'>Contact Us</Link>
                                            <Link to='/missionstatement'>Mission Statement</Link>
                                            <a onClick={() => handleOpenAccessModal()} target="_blank">Accessibility Standards (English & French)</a>
                                        </Col>
                                        <Col className="footer-heading">
                                            <h2>SERVICES</h2>
                                            <Link to='/digitalsolutions'>Connectivity</Link>
                                            <Link to='/logistics'>Logistics</Link>
                                            <Link to='/trucking'>Trucking</Link>
                                            <Link to='/warehousing'>Warehousing</Link>

                                            {/**          <Link to='/requestquote'>Request Quote</Link> */}
                                        </Col>
                                        <Col className="footer-heading">
                                            <h2>FORMS</h2>
                                            {/*<a href={claimForm} target="_blank">Claim</a>*/}
                                            <a onClick={() => handleOpenModal()} target="_blank">Claims</a>
                                            <a href={claimProcedure} target="_blank">Claim Procedure</a>
                                            <a href={creditApplication} target="_blank">Credit Application</a>
                                            <a href={creditAuthorization} target="_blank">Credit Authorization</a>
                                            <Link to='/requestQuote'>Request A Quote</Link>
                                        </Col>
                                        <Col className="footer-heading">
                                            <h2>INVESTORS</h2>
                                            <Link to='/investors'>Investor Relations</Link>
                                            <a href={Presentation} target="_blank">Presentation</a>
                                            <Link to='/newsandpress'>News & Press</Link>
                                            <Link to={{ pathname: "https://www.sedar.com/DisplayProfile.do?lang=EN&issuerType=03&issuerNo=00003034" }} target="_blank">TTNM on SEDAR</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>

                            <Col xs={12} lg={2} xl={4}>
                                <Row className="img-row justify-content-center">
                                    <a target="_blank" href="https://www.theglobeandmail.com/business/rob-magazine/top-growing-companies/article-canadas-top-growing-companies-meet-430-businesses-that-will-give-you/">
                                        <img src={Globe} alt="The Globe and Mail 2022 Top Growing Business" />
                                    </a>
                                    <a target="_blank" href="https://www.trucknews.com/top-100/">
                                        <img src={Trucking} alt="Trucking Today Top 100" />
                                    </a>
                                    <a target="_blank" href="https://www.epa.gov/smartway/smartway-partner-list">
                                        <img src={Smartway} alt="SmartWay transport Partener" />
                                    </a>
                                </Row>
                            </Col>

                            <Col xs={12} lg={5} xl={4}>
                                <div className="footer-email-form" >
                                    <form onSubmit={sendEmail}>
                                        <Container fluid className="p-0 m-0" >
                                            <Row>
                                                <h2 style={{ textAlign: 'center' }}>SUBSCRIBE TO TITANIUM NEWS</h2>
                                            </Row>
                                            <Row>
                                                <Col sm={6} xs={12} className='p-2'>
                                                    First Name
                                                    <Input fluid size="small" label={{ icon: 'asterisk' }} labelPosition='right corner' placeholder="First name" className="noPadding" name="firstName" autoComplete="false" type="text" required />
                                                </Col>
                                                <Col sm={6} xs={12} className='p-2'>
                                                    Last Name
                                                    <Input fluid size="small" label={{ icon: 'asterisk' }} labelPosition='right corner' placeholder="Last name" name="lastName" autoComplete="false" type="text" required />
                                                </Col>
                                                <Col sm={6} xs={12} className='p-2'>
                                                    Email Address
                                                    <Input fluid size="small" label={{ icon: 'asterisk' }} labelPosition='right corner' placeholder="Email Address" name="email" autoComplete="false" type="email" required />
                                                </Col>
                                                <Col sm={6} xs={12} className='p-2'>
                                                    Company
                                                    <Input fluid size="small" labelPosition='right corner' placeholder="Company" name="company" type="text" autoComplete="false" required />
                                                </Col>
                                            </Row>
                                            <Row><span style={{ fontSize: "80%", fontStyle: "italic", textAlign: "center" }}>We treat your data confidentially and do not share any information with third parties.</span></Row>
                                            <Row className="justify-content-end"><button className="btn-outline" type="submit">SUBSCRIBE</button></Row>
                                        </Container>
                                    </form>
                                    <p style={{ fontSize: "90%", fontStyle: "italic", textAlign: "center" }}>{subscriptionFormMessage}</p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <div className="copyright-info" style={{ color: '#fff', fontSize: '12px', fontStyle: 'italic', alignContent: 'center' }}>© 2021 TITANIUM TRANSPORTATION GROUP, INC. ALL RIGHTS RESERVED</div>
                </div>


            </div>

        </>
    )
}


export default Footer;